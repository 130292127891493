import { createContext, type Dispatch, type SetStateAction, useContext } from 'react';

import { type CalculationRequest, type PaymentCategory, type Statement } from '@amalia/core/types';
import { assert } from '@amalia/ext/typescript';
import { type StatementThread, type StatementThreadScope } from '@amalia/payout-collaboration/comments/types';
import { type ObjectsToDisplay } from '@amalia/payout-definition/plans/types';

export type LaunchCalculation = (
  calculationRequest?: Pick<CalculationRequest, 'dataConnectorObjectsNames' | 'shouldComputeForecast'>,
) => Promise<void> | void;

export interface StatementDetailContextInterface {
  launchCalculation: LaunchCalculation;
  calculationRequest: CalculationRequest | null;
  stopCalculation: () => Promise<void>;
  isCurrentStatementComputing: boolean;
  isForecastedView: boolean;

  paymentCategory: PaymentCategory;
  setPaymentCategory: Dispatch<SetStateAction<PaymentCategory>>;

  openStatementThreadPanel: (statementThread?: StatementThread, scope?: StatementThreadScope) => void;
  statementThreads: Record<string, StatementThread>;
  objectsToDisplay?: Record<string, ObjectsToDisplay> | null;
  statement: Statement; // Statement which can some fields replaced by forecasted values in forecasted view.
  originalStatement?: Statement; // When we are in forecast we need to have the original statement to show the diff
}

const StatementContext = createContext<StatementDetailContextInterface | null>(null);

export const StatementDetailContextProvider = StatementContext.Provider;

export const useStatementDetailContext = () => {
  const context = useContext(StatementContext);
  assert(context, 'useStatementDetailContext must be used within a StatementDetailContext');
  return context;
};
