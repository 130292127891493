import { http } from '@amalia/core/http/client';
import { type Period, type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

export class PeriodsApiClient {
  public static async getPeriodByDate({
    dateString,
    frequency,
  }: {
    dateString: string;
    frequency: PeriodFrequencyEnum;
  }): Promise<Period> {
    const { data: period } = await http.get<Period>(`/periods/${encodeURIComponent(dateString)}`, {
      params: {
        shouldCreate: true,
        frequency,
      },
    });
    return period;
  }

  public static async getAllPeriods(): Promise<Period[]> {
    const { data: period } = await http.get<Period[]>('/periods');
    return period;
  }
}
