import { http } from '@amalia/core/http/client';
import { type DataRefreshment, type RefreshmentOptions } from '@amalia/core/types';

const e = encodeURIComponent;

export class DataImportsApiClient {
  public static async getLastConnectorImport(
    connectorId: string,
    object: { name: string },
  ): Promise<DataRefreshment | null> {
    const { data } = await http.get<DataRefreshment>(`/connectors/${e(connectorId)}/refreshments/last`, {
      params: {
        objectName: object.name,
      },
    });

    return data;
  }

  public static async getLastDataImportOfEachRecordModel(): Promise<DataRefreshment[]> {
    const { data } = await http.get<DataRefreshment[]>('/connectors/refreshments/last');
    return data;
  }

  public static async create(options: RefreshmentOptions): Promise<void> {
    await http.post('/refreshments', options);
  }
}
