export enum CompanyFeatureFlags {
  BADGES = 'BADGES',
  CALCULATE_STATEMENT_TWICE = 'CALCULATE_STATEMENT_TWICE',
  CR_CALCULATED_FIELDS = 'CR_CALCULATED_FIELDS',
  CSM_CHAT = 'CSM_CHAT',
  CUSTOM_DASHBOARDS = 'CUSTOM_DASHBOARDS',
  DATA_CONNECTOR_POWER_BI = 'DATA_CONNECTOR_POWER_BI',
  DATA_REFRESH_MANAGERS = 'DATA_REFRESH_MANAGERS',
  FINANCE_USER_ROLE = 'FINANCE_USER_ROLE',
  FORECAST = 'FORECAST',
  HUBSPOT_TEAM_SYNC = 'HUBSPOT_TEAM_SYNC',
  PAYMENTS_LOCK = 'PAYMENTS_LOCK',
  PLAN_AGREEMENTS = 'PLAN_AGREEMENTS',
  RULE_TEMPLATE = 'RULE_TEMPLATE',
  SFDC_TEAM_SYNC = 'SFDC_TEAM_SYNC',
  STATEMENT_EVOLUTION = 'STATEMENT_EVOLUTION',
  STATEMENT_PDF_EXPORT = 'STATEMENT_PDF_EXPORT',
  USER_CONNECTOR_ADP = 'USER_CONNECTOR_ADP',
  USER_CONNECTOR_BAMBOO = 'USER_CONNECTOR_BAMBOO',
  USER_CONNECTOR_PAYFIT = 'USER_CONNECTOR_PAYFIT',
  USER_CONNECTOR_PERSONIO = 'USER_CONNECTOR_PERSONIO',
  USER_CONNECTOR_SAGE = 'USER_CONNECTOR_SAGE',
  USER_CONNECTOR_SAP = 'USER_CONNECTOR_SAP',
  USER_CONNECTOR_WORKDAY = 'USER_CONNECTOR_WORKDAY',
}
