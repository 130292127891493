import { createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';

import { type LaunchCalculation } from './useStatementDetail.context';

export interface StatementCalculationContextInterface {
  launchCalculation: LaunchCalculation;
  stopCalculation: () => Promise<void>;
  isCalculationRunning: boolean;
}

const StatementCalculationContext = createContext<StatementCalculationContextInterface | null>(null);

export const StatementCalculationContextProvider = StatementCalculationContext.Provider;

export const useStatementCalculationContext = () => {
  const context = useContext(StatementCalculationContext);
  assert(context, 'useStatementCalculationContext must be used within a StatementCalculationContext');
  return context;
};
