import { type ComponentType } from 'react';

import { type DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import {
  CustomObjectDefinitionType,
  CustomObjectDefToDataConnectorType,
} from '@amalia/data-capture/record-models/types';
import { type Asset } from '@amalia/ext/react/types';
import { isEnum } from '@amalia/ext/typescript';
import { IconAmaliaLogo } from '@amalia/frontend/ui-icons';

import { VENDOR_LOGOS } from './connectors.logos';

export function getVendorLogo<TFallback extends ComponentType>(
  objectDefinitionType: DataConnectorTypes,
  fallback?: TFallback | null,
): Asset;

export function getVendorLogo(
  objectDefinitionType: CustomObjectDefinitionType | DataConnectorTypes | null | undefined,
  fallback: null,
): Asset | null;

export function getVendorLogo<TFallback extends ComponentType>(
  objectDefinitionType?: CustomObjectDefinitionType | DataConnectorTypes | null,
  fallback?: TFallback,
): Asset | TFallback;

export function getVendorLogo<TFallback extends ComponentType>(
  objectDefinitionType?: CustomObjectDefinitionType | DataConnectorTypes | null,
  fallback?: TFallback | null,
): Asset | TFallback | null;

export function getVendorLogo<TFallback extends ComponentType>(
  objectDefinitionType?: CustomObjectDefinitionType | DataConnectorTypes | null,
  fallback: TFallback | null = IconAmaliaLogo as TFallback,
): Asset | TFallback | null {
  if (!objectDefinitionType) {
    return fallback;
  }

  const dataConnectorType = isEnum(objectDefinitionType, CustomObjectDefinitionType)
    ? CustomObjectDefToDataConnectorType[objectDefinitionType]
    : objectDefinitionType;

  return dataConnectorType && dataConnectorType in VENDOR_LOGOS
    ? VENDOR_LOGOS[dataConnectorType].CompactLogo
    : fallback;
}
