import { css } from '@emotion/react';
import { memo, type ReactNode } from 'react';

import { useBoolState } from '@amalia/ext/react/hooks';

import { Collapse, type CollapseProps } from '../../../general/collapse/Collapse';
import { CollapseIconChevron } from '../../../general/collapse-icon-chevron/CollapseIconChevron';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../general/typography/Typography';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { Group } from '../../../layout/group/Group';
import { Stack } from '../../../layout/stack/Stack';

export type SideMenuCollapseProps = Pick<CollapseProps, 'lazy'> & {
  /** Section label. */
  readonly label: ReactNode;
  /** Section items. */
  readonly children?: ReactNode;
};

export const SideMenuCollapse = memo(function SideMenuCollapse({ label, lazy, children }: SideMenuCollapseProps) {
  const { isExpanded, toggleExpanded } = useBoolState(false, 'expanded');

  return (
    <div
      css={css`
        padding: 0 8px;
      `}
    >
      <UnstyledButton
        css={(theme) => css`
          width: 100%;

          border-radius: ${theme.ds.borderRadiuses.squared};
          &:focus-visible {
            outline: 1px solid ${theme.ds.colors.primary[200]};
            outline-offset: -1px;
            box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
          }
        `}
        onClick={toggleExpanded}
      >
        <Group
          data-side-menu-item
          align="center"
          gap={8}
          justify="space-between"
          css={css`
            height: 32px;
            padding: 0 8px;
          `}
        >
          <Typography
            as={TextOverflow}
            variant={Typography.Variant.BODY_SMALL_MEDIUM}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[700]};
            `}
          >
            {label}
          </Typography>

          <CollapseIconChevron
            isOpen={isExpanded}
            size={14.4}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[800]};
            `}
          />
        </Group>
      </UnstyledButton>

      <Collapse
        isOpen={isExpanded}
        lazy={lazy}
      >
        <Stack
          as="ul"
          gap={1}
          css={css`
            list-style-type: none;

            &:empty {
              display: none;
            }
          `}
        >
          {children}
        </Stack>
      </Collapse>
    </div>
  );
});
