import { type DataConnector, type RecordContent } from '@amalia/data-capture/connectors/types';
import { type Company } from '@amalia/tenants/companies/types';

import { type Calculation, type ScheduleCalculationEvent } from './calculation';

export enum CustomObjectImportStatus {
  /* new data waiting to be imported */
  PENDING = 'PENDING',
  /* data import has been started */
  STARTED = 'STARTED',
  /* All data have been imported */
  SUCCESS = 'SUCCESS',
  /* Import has failed */
  ERROR = 'ERROR',
  /* Import is requested to stop, not stopped yet */
  STOPPING = 'STOPPING',
  /* Import was stopped */
  STOPPED = 'STOPPED',
  /* Some of the datas have not been imported */
  INCOMPLETE = 'INCOMPLETE',
}

// If the refreshment has one of this statuses, it means it's ongoing.
export const REFRESHMENTS_ONGOING_STATUSES = [
  CustomObjectImportStatus.PENDING,
  CustomObjectImportStatus.STARTED,
  CustomObjectImportStatus.STOPPING,
];

export enum IndexationType {
  /* Full reindex */
  FULL = 'FULL',
  /* Partial reindex */
  PARTIAL = 'PARTIAL',
}

export interface RefreshmentOptions {
  indexationType: IndexationType;

  // List of objects we want to refresh.
  // If null, it means that we want to refresh the whole company.
  dataConnectorObjectsNames:
    | {
        // Id of the connector. We actually need both custom object name and connector to be able to retrieve the definition.
        id: DataConnector['id'];
        name: string;
      }[]
    | null;

  // Should launch calculation at the end of the refreshment?
  willLaunchCalculation: boolean;

  // Options for the triggered calculation
  calculationsOptions?: ScheduleCalculationEvent['options'];

  // Should prune old data at the end of the refreshment?
  willPrune: boolean;

  // Start offset to avoid timout on large refreshment
  startOffset: number;
}

export type DataRefreshmentValidationError = {
  row: RecordContent;
  error: string;
};

export enum RefreshmentErrorType {
  TOKEN = 'TOKEN',
  CONFIGURATION = 'CONFIGURATION',
  CONNECTION = 'CONNECTION',
  STOPPED = 'STOPPED',
  OTHER = 'OTHER',
}

export enum RefreshmentErrorLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export interface RefreshmentError {
  message: string;
  type: RefreshmentErrorType;
  level: RefreshmentErrorLevel;
  stack?: string;
}

export type DataRefreshment = {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  company?: Company;
  connector: DataConnector;
  object: string;

  options: RefreshmentOptions;
  status: CustomObjectImportStatus;

  startDate: Date;
  endDate: Date;
  totalUpdate: number;
  updated: number;

  totalDelete: number;
  deleted: number;

  validationErrors: DataRefreshmentValidationError[];
  validationErrorsCount: number;
  error?: RefreshmentError;

  // If the refreshment was triggered by a calculation, save its id.
  triggerCalculationId?: Calculation['id'];
};
