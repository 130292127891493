import { css } from '@emotion/react';
import { Fragment, type ReactNode, memo } from 'react';

import { CircularLoader, Group, LinearLoader } from '@amalia/design-system/components';

export type PageContainerProps = {
  readonly showLoadingBar?: boolean;
  readonly isLoading?: boolean;
  readonly className?: string;
  readonly children?: ReactNode;
};

export const PageContainer = memo(function PageContainer({
  showLoadingBar = false,
  isLoading = false,
  className = undefined,
  children = undefined,
}: PageContainerProps) {
  return (
    <Fragment>
      {!!showLoadingBar && (
        <LinearLoader
          size={4}
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
          `}
        />
      )}

      <div
        className={className}
        css={[
          css`
            --page-container-max-width: 1920px;
            --page-container-padding-x: 16px;

            min-height: 100%;
            width: 100%;
            max-width: var(--page-container-max-width);

            margin: 0 auto;

            position: relative;
            padding-bottom: 40px;
            padding-top: 24px;
            padding-left: var(--page-container-padding-x);
            padding-right: var(--page-container-padding-x);

            @media (min-width: 600px) {
              --page-container-padding-x: 24px;
            }
          `,
        ]}
      >
        {isLoading ? (
          <Group
            align="center"
            justify="center"
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            `}
          >
            <CircularLoader size={CircularLoader.Size.LARGE} />
          </Group>
        ) : (
          children
        )}
      </div>
    </Fragment>
  );
});
