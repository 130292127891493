import { useMutation, useQueryClient } from '@tanstack/react-query';

import { dataImportsQueryKeys } from '@amalia/data-capture/imports/state';
import {
  addCompany,
  saCreateCalculation,
  saModifyCompanyProperty,
  saSetCalculationStatus,
  saSetImpersonateUser,
  saSetMasterSettings,
  saSetRefreshmentStatus,
} from '@amalia/superadmin/api-client';

import { superadminQueryKeys } from './queries.key';

export const useSetMasterSettingsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetMasterSettings>) => saSetMasterSettings(...params),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: superadminQueryKeys.masterSettings() }),
  });
};

export const useAddCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addCompany,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: superadminQueryKeys.companies.all() }),
  });
};

export const useUpdateCompanyPropertyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saModifyCompanyProperty>) => saModifyCompanyProperty(...params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: superadminQueryKeys.companies.all() });
    },
  });
};

export const useImpersonateUserMutation = ({ redirectToHome = false }: { redirectToHome?: boolean } = {}) =>
  useMutation({
    mutationFn: (email: string) => saSetImpersonateUser(email),
    onSuccess: () => {
      if (redirectToHome) {
        window.location.replace('/');
        return;
      }
      window.location.reload();
    },
  });

export const useSetCalculationStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetCalculationStatus>) => saSetCalculationStatus(...params),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: superadminQueryKeys.calculations.all() }),
  });
};

export const useLaunchCalculationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saCreateCalculation>) => saCreateCalculation(...params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: superadminQueryKeys.calculations.all() });
    },
  });
};

export const useStopRefreshmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetRefreshmentStatus>) => saSetRefreshmentStatus(...params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: dataImportsQueryKeys.all() });
      await queryClient.invalidateQueries({ queryKey: superadminQueryKeys.dataUpdates.all() });
    },
  });
};
