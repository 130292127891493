import { Global, type Theme, css } from '@emotion/react';

const is1stApril = new Date().getMonth() === 3 && new Date().getDate() === 1;
export const robotoGFontsQS = is1stApril ? 'family=Comic+Neue:wght@400;500;700' : 'family=Roboto:wght@400;500;700';
export const robotoMonoGFontsQS = 'family=Roboto+Mono:wght@400';

export const DEFAULT_THEME_GLOBAL_STYLES: Theme['ds']['globalStyles'] = (
  <Global
    styles={[
      (theme) => css`
        @import url('https://fonts.googleapis.com/css2?${robotoGFontsQS}&${robotoMonoGFontsQS}&display=swap');

        /* Reset gutters. */
        *,
        *::after,
        *::before {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }

        * {
          // For text overflow in flex children, you need min-width 0 pretty much everywhere between the flex container and the text content.
          // Setting min-width 0 here does not have any impact (in places where a min-width is needed it's already set).
          min-width: 0;
        }

        html,
        body {
          // Set in index.html. Do not set on #root because of z-index issues.
          background: var(--app-background-color);
        }

        html,
        body,
        #root {
          font-family: ${theme.ds.typographies.bodyBaseRegular.fontFamily};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          height: 100%;
          width: 100%;

          font-size: 16px;

          color: ${theme.ds.colors.gray[900]};

          text-wrap: pretty;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          text-wrap: balance;
        }
      `,

      // on 1st April, add font-face for 500 to use Comic Neue 700
      is1stApril &&
        css`
          @font-face {
            font-family: 'Comic Neue';
            font-weight: 500;
            src: url('https://fonts.gstatic.com/s/comicneue/v8/4UaErEJDsxBrF37olUeD_xHM8pxULg.woff2') format('woff2');
          }
        `,
    ]}
  />
);
