import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { REFRESHMENTS_ONGOING_STATUSES } from '@amalia/core/types';
import { type DataConnector } from '@amalia/data-capture/connectors/types';
import { DataImportsApiClient } from '@amalia/data-capture/imports/api-client';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

import { dataImportsQueryKeys } from './queries.keys';

export const useLastConnectorImport = (
  connectorId?: DataConnector['id'],
  objectDefinitionName?: CustomObjectDefinition['name'],
) =>
  useQuery({
    enabled: !!connectorId && !!objectDefinitionName,
    queryKey: dataImportsQueryKeys.lastConnectorImport(connectorId!, objectDefinitionName!),
    queryFn: () => DataImportsApiClient.getLastConnectorImport(connectorId!, { name: objectDefinitionName! }),
    refetchInterval: (query) =>
      query.state.data && REFRESHMENTS_ONGOING_STATUSES.includes(query.state.data.status) ? 5000 : false,
  });

export const useLastDataImportOfEachRecordModel = () =>
  useQuery({
    queryKey: dataImportsQueryKeys.lastRecordModelsImport(),
    queryFn: DataImportsApiClient.getLastDataImportOfEachRecordModel,
    refetchInterval: (query) =>
      query.state.data?.some((dataImport) => REFRESHMENTS_ONGOING_STATUSES.includes(dataImport.status))
        ? 30_000
        : false,
  });

/**
 * React query mutation hook to create a data refreshment.
 */
export const useCreateDataImport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DataImportsApiClient.create,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: dataImportsQueryKeys.all() });
    },
  });
};
