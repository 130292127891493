export enum DataConnectorTypes {
  SALESFORCE = 'salesforce',
  HUBSPOT = 'hubspot',
  ZOHO = 'zoho',
  NETSUITE = 'netsuite',
  GOOGLESHEET = 'googlesheet',
  REDSHIFT = 'redshift',
  BIGQUERY = 'bigquery',
  SNOWFLAKE = 'snowflake',
  POSTGRESQL = 'postgresql',
  POWERBI = 'powerbi',
  MSDYNAMICS = 'msdynamics',
  SQLSERVER = 'sqlserver',
  MYSQL = 'mysql',
  ADPWORKFORCENOW = 'adp-workforce-now',
  BAMBOOHR = 'bamboohr',
  PERSONIO = 'personio',
  SAGEHR = 'sage-hr',
  SAPSUCCESSFACTORS = 'sap-successfactors',
  WORKDAY = 'workday',
}
