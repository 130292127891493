import { css } from '@emotion/react';
import { IconHelp } from '@tabler/icons-react';
import { isNil } from 'lodash';
import { type ReactNode, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '../../../general/typography/Typography';
import { Group } from '../../../layout/group/Group';
import { Stack } from '../../../layout/stack/Stack';
import { Tooltip } from '../../../overlays/tooltip/Tooltip';

export type FatTabInnerProps = {
  readonly data?: ReactNode;
  readonly label: ReactNode;
  readonly subtitle?: ReactNode;
  readonly tooltip?: ReactNode;
  readonly isLoading?: boolean;
  readonly loadingComponent?: ReactNode;
};

export const FatTabInner = memo(function FatTabInner({
  data,
  label,
  subtitle,
  tooltip,
  isLoading,
  loadingComponent,
}: FatTabInnerProps) {
  return (
    <Group grow>
      <Stack
        grow
        gap={4}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 2px;
          `}
        >
          <Typography
            data-fat-tab-data="true"
            variant={Typography.Variant.HEADING_3_MEDIUM}
            css={(theme) => css`
              color: ${isLoading
                ? theme.ds.colors.gray[400]
                : isNil(data)
                  ? theme.ds.colors.gray[500]
                  : theme.ds.colors.gray[900]};
            `}
          >
            {data ?? <FormattedMessage defaultMessage="No data" />}
          </Typography>

          <div
            css={(theme) => css`
              display: flex;
              align-items: center;
              gap: 6px;
              color: ${theme.ds.colors.gray[800]};

              > svg {
                flex: none;
              }
            `}
          >
            <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>{label}</Typography>

            {!!tooltip && (
              <Tooltip content={tooltip}>
                <IconHelp size={14} />
              </Tooltip>
            )}
          </div>
        </div>

        {!!subtitle && (
          <Typography
            variant={Typography.Variant.BODY_XSMALL_REGULAR}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[700]};
            `}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>

      {!!isLoading && (
        <Group
          align="center"
          justify="center"
        >
          {loadingComponent}
        </Group>
      )}
    </Group>
  );
});
