import { type DataConnector, type DataConnectorObject } from '@amalia/data-capture/connectors/types';
import { type Company } from '@amalia/tenants/companies/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { type EngineError } from './calculationErrors';
import { type DataRefreshment, type RefreshmentOptions } from './refreshment';

export enum CalculationStatus {
  // New calculation waiting to be launched.
  PENDING = 'PENDING',
  // A refresh is ongoing.
  REFRESH = 'REFRESH',
  // Calculation has been started.
  STARTED = 'STARTED',
  // All calculation have been done.
  SUCCESS = 'SUCCESS',
  // Calculation has failed.
  ERROR = 'ERROR',
  // Some of the calculations have not been done.
  INCOMPLETE = 'INCOMPLETE',
  // User requested stop.
  STOPPING = 'STOPPING',
  // Calculation successfully stopped.
  STOPPED = 'STOPPED',
}

export enum CalculationTrigger {
  DATA_REFRESHMENT = 'DATA_REFRESHMENT',
  MANUAL = 'MANUAL',
  NIGHTLY_CALCULATION = 'NIGHTLY_CALCULATION',
}

export enum CalculationType {
  STATEMENT = 'STATEMENT',
  FORECAST = 'FORECAST',
}

export interface CalculationDescriptorResult {
  status: CalculationStatus;
  statementId: string;
  error: string | null;
}

export interface CalculationDescriptorBatch {
  status: CalculationStatus;
  planId: string;
  planName: string;
  users: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    pictureURL?: string | null;
    result?: CalculationDescriptorResult;
  }[];
}

export interface CalculationDescriptorStep {
  periodId: string;
  periodName: string;
  plansWeight: number;
  batches: CalculationDescriptorBatch[];
}

export type CalculationDescriptor = CalculationDescriptorStep[];

export const CALCULATION_ONGOING_STATUSES = [
  CalculationStatus.PENDING,
  CalculationStatus.REFRESH,
  CalculationStatus.STARTED,
  CalculationStatus.STOPPING,
];

export interface Calculation {
  id: string;

  createdAt: Date;

  company?: Company;

  companyId: string;

  status: CalculationStatus;

  total?: number;

  calculated?: number;

  error?: string;

  errorMetadata?: EngineError;

  finishedAt?: Date;

  lastUpdatedAt?: Date;

  withRollingPeriods?: boolean;

  descriptor: CalculationDescriptor;

  uniqueUserKey?: string;

  type?: CalculationType;

  trigger: CalculationTrigger;

  deletedStatementIds?: string[] | null;

  shouldComputeForecast?: boolean;

  creator?: UserContract | null;

  creatorId?: string | null;

  // Older calculations will not have this field.
  query: CalculationRequest | null;
}

export const CALCULATION_BATCH_SIZE = 20;

export const CALCULATION_MAX_PARALLEL_BATCHES = 10;

export interface CalculationRequest {
  // The period used as a reference. If undefined, we're
  // going to take the current one. If we have forward/backward
  // rolling periods, they're going computed around this one.
  periodId?: string;

  // Trigger of the computation (manual, after refresh...).
  trigger?: CalculationTrigger;

  // User ids to compute.
  userIds?: string[];

  // Team ids to compute.
  teamIds?: string[];

  // Plans to compute.
  planIds?: string[];

  // Achieved or forecast.
  type?: CalculationType;

  // If we need to refresh some objects before computing.
  dataConnectorObjectsNames?: RefreshmentOptions['dataConnectorObjectsNames'];

  shouldComputeForecast?: Calculation['shouldComputeForecast'];
}

export interface PatchCalculationRequest {
  status: CalculationStatus;
}

export interface CalculationAnalyze {
  calculation: Omit<Calculation, 'createdAt' | 'id' | 'updatedAt'>;
  records: Record<
    string,
    {
      source: DataConnectorObject;
      lastRefresh: DataRefreshment | null;
      connectorType: DataConnector['type'];
      connectorId: DataConnector['id'];
    }
  >;
}

export interface SaCalculationRequest extends CalculationRequest {
  companyId: string;
}

export interface ScheduleCalculationEvent {
  companyId: string;

  // The original CalculationRequest on which the calculation
  // has been started.
  query: CalculationRequest;

  options: {
    // This might help to stop an ongoing computation on the same statement.
    uniqueUserKey?: string;

    // Should compute rolling periods as well?
    withRollingPeriods: boolean;

    // Will take priority over query.periodId
    // If the rolling periods have been interpreted in a list of period
    // ids, we find their value here.
    resolvedPeriodIds?: string[];
  };

  //If this new calculation has been triggered from another one.
  // For example achieved + forecast calculation.
  // Forecast calculation is triggered by the achieved one (if calculateBothStatements is true for example).
  // In that case triggeredFromCalculationId is the achieved calculationId.
  triggeredFromCalculationId?: string;
}

export interface StatementDatasetCacheBurstEvent {
  companyId?: string;
}

export interface ExecutePlanTemplateBuildEvent {
  companyId: string;
  planIds?: string[];
}

export enum RefreshmentAndCalculationStopActions {
  STOP = 'stop',
  FORCE_STOP = 'forcestop',
}
