import { DataConnectorTypes } from './connector-types';
import { DataConnectorFilterOperator } from './data-connector';

// Common SQL operators for all connectors that use SQL Utils
export const sqlFilterOperators = {
  [DataConnectorFilterOperator.EQ]: '=',
  [DataConnectorFilterOperator.NEQ]: '!=',
  [DataConnectorFilterOperator.LT]: '<',
  [DataConnectorFilterOperator.LTE]: '<=',
  [DataConnectorFilterOperator.GT]: '>',
  [DataConnectorFilterOperator.GTE]: '>=',
  [DataConnectorFilterOperator.CONTAINS]: null,
  [DataConnectorFilterOperator.HAS_PROPERTY]: null,
  [DataConnectorFilterOperator.IN]: null,
};

// Returns, for a given connector and filter operator, its translation in the connector type.
// If result is null, there is no correspondence.
export const filtersOperationsPerConnector: Record<
  DataConnectorTypes,
  Partial<Record<DataConnectorFilterOperator, string | null>> | null
> = {
  [DataConnectorTypes.BIGQUERY]: sqlFilterOperators,
  [DataConnectorTypes.GOOGLESHEET]: {
    [DataConnectorFilterOperator.EQ]: null,
    [DataConnectorFilterOperator.NEQ]: null,
    [DataConnectorFilterOperator.LT]: null,
    [DataConnectorFilterOperator.LTE]: null,
    [DataConnectorFilterOperator.GT]: null,
    [DataConnectorFilterOperator.GTE]: null,
    [DataConnectorFilterOperator.CONTAINS]: null,
    [DataConnectorFilterOperator.HAS_PROPERTY]: null,
  },
  [DataConnectorTypes.HUBSPOT]: {
    [DataConnectorFilterOperator.EQ]: 'EQ',
    [DataConnectorFilterOperator.NEQ]: 'NEQ',
    [DataConnectorFilterOperator.LT]: 'LT',
    [DataConnectorFilterOperator.LTE]: 'LTE',
    [DataConnectorFilterOperator.GT]: 'GT',
    [DataConnectorFilterOperator.GTE]: 'GTE',
    [DataConnectorFilterOperator.CONTAINS]: 'CONTAINS_TOKEN',
    [DataConnectorFilterOperator.HAS_PROPERTY]: 'HAS_PROPERTY',
  },
  [DataConnectorTypes.MYSQL]: sqlFilterOperators,
  [DataConnectorTypes.NETSUITE]: sqlFilterOperators,
  [DataConnectorTypes.POSTGRESQL]: sqlFilterOperators,
  [DataConnectorTypes.POWERBI]: null,
  [DataConnectorTypes.MSDYNAMICS]: null,
  [DataConnectorTypes.REDSHIFT]: sqlFilterOperators,
  [DataConnectorTypes.SALESFORCE]: sqlFilterOperators,
  [DataConnectorTypes.SNOWFLAKE]: sqlFilterOperators,
  [DataConnectorTypes.SQLSERVER]: sqlFilterOperators,
  [DataConnectorTypes.ADPWORKFORCENOW]: null,
  [DataConnectorTypes.BAMBOOHR]: null,
  [DataConnectorTypes.SAGEHR]: null,
  [DataConnectorTypes.SAPSUCCESSFACTORS]: null,
  [DataConnectorTypes.PERSONIO]: {
    [DataConnectorFilterOperator.EQ]: 'EQ',
    [DataConnectorFilterOperator.NEQ]: 'NEQ',
    [DataConnectorFilterOperator.CONTAINS]: 'CONTAINS_TOKEN',
    [DataConnectorFilterOperator.HAS_PROPERTY]: 'HAS_PROPERTY',
  },
  [DataConnectorTypes.WORKDAY]: null,
  [DataConnectorTypes.ZOHO]: null,
} as const;
